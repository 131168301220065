import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "./Admin/Login.jsx";
import AdminHome from "./Admin/AdminHome";
import Careers_Remaining from "./Admin/Careers_Remaining";
import Careers_All from "./Admin/Careers_All";
import Contacts_Remaining from "./Admin/Contacts_Remaining";
import Contacts_All from "./Admin/Contacts_All";
import Blogs_All from "./Admin/Blogs_All";
import Blog_Edit from "./Admin/Blog_Edit";
import Blog_Add from "./Admin/Blog_Add";
import Roles from "./Admin/Roles";
import OfferingsAll from "./Admin/OfferingsAll";
import OfferingEdit from "./Admin/OfferingEdit";
import OfferingAdd from "./Admin/OfferingAdd";
import ProductsAll from "./Admin/ProductsAll";
import ProductsEdit from "./Admin/ProductsEdit";
import ProductsAdd from "./Admin/ProductsAdd";
import Navbar from "./Landing-Page/Navbar";
import Footer from "./Landing-Page/Footer";
import Home from "./Landing-Page/Home";
import About from "./Landing-Page/About";
import Offerings from "./Landing-Page/Offerings";
import Community from "./Landing-Page/Community";
import Blogs from "./Landing-Page/Blogs";
import BlogsPost from "./Blogs/BlogsPost";
import Careers from "./Landing-Page/Careers";
import Contact from "./Landing-Page/Contact";
import Gynaecology from "./Offerings/Gynaecology";
import Cardiovascular from "./Offerings/Cardiovascular";
import Diabetics from "./Offerings/Diabetics";
import Respiratory from "./Offerings/Respiratory";
import Dermatology from "./Offerings/Dermatology";
import Analgesics from "./Offerings/Analgesics";
import Infectives from "./Offerings/Infectives";
import Gastrology from "./Offerings/Gastrology";
import Vitamins from "./Offerings/Vitamins";
import Products from "./Products/Products";
import Error from "./Error";
import ProductPage from "./Products/ProductPage";
import Sitemap from "./Sitemap/Sitemap";

const PublicRoute = ({ component: Component, ...rest }) => {
  const component = (props) => (
    <div>
      <Navbar />
      <Component {...props} />
      <Footer />
    </div>
  );
  return <Route {...rest} component={component} />;
};

function App() {
  return (
    <div>
      <div id="canvas">
        <div id="box_wrapper">
          {/* <Router> */}
          <Switch>
            {/* <Route exact path="/admin/login" component={Login} /> */}
            <PrivateRoute exact path="/admin/home" component={AdminHome} />
            <PrivateRoute
              exact
              path="/admin/careers/to_be_contacted"
              component={Careers_Remaining}
            />
            <PrivateRoute
              exact
              path="/admin/careers/all"
              component={Careers_All}
            />
            <PrivateRoute
              exact
              path="/admin/contacts/to_be_contacted"
              component={Contacts_Remaining}
            />
            <PrivateRoute
              exact
              path="/admin/contacts/all"
              component={Contacts_All}
            />
            <PrivateRoute exact path="/admin/blogs/all" component={Blogs_All} />
            <PrivateRoute exact path="/admin/blogs/add" component={Blog_Add} />
            <PrivateRoute
              exact
              path="/admin/blogs/:blog_name"
              component={Blog_Edit}
            />
            <PrivateRoute exact path="/admin/roles/all" component={Roles} />
            <PrivateRoute
              exact
              path="/admin/offerings/all"
              component={OfferingsAll}
            />
            <PrivateRoute
              exact
              path="/admin/offerings/add/:offering_name"
              component={OfferingAdd}
            />
            <PrivateRoute
              exact
              path="/admin/offerings/:offering_name"
              component={OfferingEdit}
            />
            <PrivateRoute
              exact
              path="/admin/products/all"
              component={ProductsAll}
            />
            <PrivateRoute
              exact
              path="/admin/products/add"
              component={ProductsAdd}
            />
            <PrivateRoute
              exact
              path="/admin/products/:id/:product_name"
              component={ProductsEdit}
            />
            <PublicRoute exact path="/" component={Home} />
            <PublicRoute exact path="/about" component={About} />
            <PublicRoute exact path="/offerings" component={Offerings} />
            <PublicRoute
              exact
              path="/offerings/gynaecology"
              component={Gynaecology}
            />
            <PublicRoute
              exact
              path="/offerings/cardiovascular_metabolics"
              component={Cardiovascular}
            />
            <PublicRoute
              exact
              path="/offerings/anti-diabetics"
              component={Diabetics}
            />
            <PublicRoute
              exact
              path="/offerings/respiratory"
              component={Respiratory}
            />
            <PublicRoute
              exact
              path="/offerings/dermatology"
              component={Dermatology}
            />
            <PublicRoute
              exact
              path="/offerings/analgesics"
              component={Analgesics}
            />
            <PublicRoute
              exact
              path="/offerings/anti-infectives"
              component={Infectives}
            />
            <PublicRoute
              exact
              path="/offerings/gastrology"
              component={Gastrology}
            />
            <PublicRoute
              exact
              path="/offerings/vitamins"
              component={Vitamins}
            />
            <PublicRoute exact path="/community" component={Community} />
            <PublicRoute exact path="/blogs" component={Blogs} />
            <PublicRoute
              exact
              path="/blogs/:blog_name"
              component={BlogsPost}
              render={(props) => (
                <BlogsPost key={props.match.params.blog_name} {...props} />
              )}
            />
            <PublicRoute exact path="/careers" component={Careers} />
            <PublicRoute exact path="/contact" component={Contact} />
            <Route
              exact
              path="/products/:product_name"
              render={(props) => (
                <div>
                  <Navbar />
                  <Products {...props} />
                </div>
              )}
            />
            <Route
              exact
              path="/:offering/:product_name"
              render={(props) => (
                <div>
                  <Navbar />
                  <ProductPage {...props} />
                </div>
              )}
            />
            <PublicRoute exact path="/sitemap" component={Sitemap} />
            <PublicRoute exact path="/404" component={Error} />
            <PublicRoute path="*" component={Error} />
          </Switch>
          {/* </Router> */}
        </div>
      </div>
    </div>
  );
}

export default App;
